import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div>
      <div className="text-center">
        <img className="mt-5 img-fluid" src="images/logo.png" alt="Logo" /> <br />
        <div className="row mt-5">
          <div className="col-12 col-md-6 offset-md-3">
            <p className="text-center">
              We are beyond excited to introduce something truly special <b>Towney!</b> Our team has been working tirelessly behind the scenes, and we can’t wait to share it with you. This project is designed to inspire, empower, and bring a fresh wave of innovation your way.
            </p>
          </div>
        </div>
        <img className="img-fluid" src="https://www.shutterstock.com/image-vector/gradient-pink-hand-drawn-coming-600nw-1023489238.jpg" alt="Coming soon" />
      </div>
    </div>

  );
}

export default App;
